import { mapActions, mapGetters } from "vuex";
import product from "../../../../components/product/index.vue";
import _ from "lodash";

export default {
    name: "profile-favorites",
    components: {
        product,
    },
    computed: {
        ...mapGetters({
            favourites: "favorites/whichList",
        }),
        favoritesProducts() {
            return _.map(this.favourites, "product.data");
        },
    },
    methods: {
        ...mapActions({}),
    },
};
